@import "shared/styles";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 20px solid rgba(50, 205, 186, 0.3);
  border-top: 20px solid rgba(233, 33, 192);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  animation: spin 2s linear infinite;

  @include xs {
    width: 70px;
    height: 70px;
    border: 8px solid rgba(50, 205, 186, 0.3);
    border-top: 8px solid rgba(233, 33, 192);
  }
}

.smallSpinner {
  width: 150px;
  height: 150px;
  border: 12px solid rgba(50, 205, 186, 0.3);
  border-top: 12px solid rgba(233, 33, 192);
  @include xs {
    width: 70px;
    height: 70px;
    border: 8px solid rgba(50, 205, 186, 0.3);
    border-top: 8px solid rgba(233, 33, 192);
  }
}
