@import "shared/styles";

.MobileOffersView {
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.biggerBottomPadding {
  padding-bottom: 100px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-bottom: 35px; */
  padding-right: 100px;
  box-sizing: border-box;

  @include xms {
    padding-right: 80px;
  }
}

.singleOfferRow {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0px;
  box-sizing: border-box;
}

.absoluteRow {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0px;
  padding-right: 0;
  left: calc(50% - 30px);
  width: auto;
  box-sizing: border-box;
  padding-top: 120px;

  @include xms {
    left: calc(50% - 25px);
    padding-top: 100px;
  }
}
