@import "shared/styles";

.NewUserStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 600px;
  margin: 0 auto 150px auto;

  @include xs {
    width: 100%;
  }

  @include lg {
    width: 1200px;
    gap: 10px;
  }
}

.form {
  width: 100%;
}

.prompt {
  font-size: 18px;
  text-align: center;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}

.dropdownsContainer {
  display: flex;
  background-color: $primary-light-blue;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;

  @include xs {
    flex-direction: column;
  }

  @include lg {
    padding: 10px;
  }
}

.dateOfBirthLabel {
  font-size: 16px;

  @include lg {
    font-size: 28px;
  }
}

.innerDropdownsContainer {
  flex: 1;
  display: flex;
  gap: 5px;

  @include xs {
    margin-left: 10px;
  }

  @include xms {
    margin-left: 5px;
  }

  @include lg {
    gap: 10px;
  }
}

.labelContainer {
  width: 220px;
  min-height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;

  @include xms {
    padding-left: 5px;
  }

  @include xs {
    width: 100%;
    min-height: 20px;
  }

  @include lg {
    width: 440px;
    min-height: 60px;
    padding-left: 20px;
  }
}

.errorText {
  margin: 0;
  color: $primary-red;
  font-family: $ff-primary;
  margin-left: auto;
  text-align: start;
  box-sizing: border-box;
  padding: 0 5px;
  font-size: 16px;
  width: 100%;

  @include lg {
    font-size: 28px;
    padding: 0 10px;
  }
}

.button {
  margin-top: 20px;
  height: 38px;
  width: 150px;

  @include xs {
    width: 130px;
    height: 40px;
  }

  @include lg {
    margin-top: 40px;
    height: 76px;
    width: 300px;
  }
}

.buttonText {
  font-size: 18px;
  letter-spacing: 1px;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}

.select {
  flex: 1;
  border-radius: 0%;
}
