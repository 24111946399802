@import "shared/styles";

.bannerContainer {
  width: 100%;
  max-width: 1000px;
  padding: 0 15px;
  height: auto;
  max-height: 500px;

  @include xs {
    max-width: 700px;
    margin-top: 80px;
  }
}

.bannerItem {
  width: 100%;
  max-width: 700px;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
}
