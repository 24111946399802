@import "shared/styles";

.SchoolPage {
  width: 100vw;
  background-image: linear-gradient(90deg, #100030 0%, #370b63 100%, #370b63 100%);
}

.container {
  margin-left: 100px;
  margin-right: 100px;

  @include xs {
    margin-left: auto;
    margin-right: auto;
  }
}

.flexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.schoolPagePicture {
  width: 100%;
  position: relative;
}

.schoolPagePicture {
  width: 100%;
  max-height: 500px; 
  object-fit: cover; 
  display: block;

  @include xs {
    max-height: 300px;
  }
}

.schoolPagePictureTextContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
}

.schoolPagePictureText {
  font-size: clamp(12px, 3vw, 58px);
  letter-spacing: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.lineOne {
  margin-bottom: 20px; 
}

.lineTwo {
  margin-bottom: 0; 
}

.schoolTitleContainer {
  max-height: 75px;
  width: 100%;
  background-image: linear-gradient(90deg, #ff29cf 0%, #40e0cb 100%);
}

.schoolTitle {
  font-size: 36px;
  letter-spacing: 4px;
  padding-top: 4px;

  @include lg {
    font-size: 48px;
    height: 100px;
  }
  @include xl {
    font-size: 58px;
    height: 125px;
  }

  @include xms {
    font-size: 20px;
    height: 50px;
  }

  @include xxs {
    font-size: 15px;
    height: 40px;
  }

  @include xs {
    font-size: 28px;
    height: 50px;
  }
}

.schoolDescription {
  font-size: 20px;
  letter-spacing: 2px;
  justify-content: center;
  padding-top: 10px;
  margin-left: 25px;
  margin-right: 25px;

  @include lg {
    font-size: 25px;
  }
  @include xl {
    font-size: 35px;
  }
  @include xms {
    font-size: 15px;
  }

  @include xxs {
    font-size: 12px;
    margin-left: 3px;
    margin-right: 3px;
  }
}

.flexContainerFeatures {
  display: flex;

  @include xs {
    flex-direction: column;
  }
}

.flexContainerFeaturesLeftColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flexContainerFeaturesRightColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.features {
  display: flex;
  text-align: left;
  align-items: center;

  @include xs {
    width: 100%;
  }

  @include xms {
    flex-direction: column;
  }

  @include xxs {
    width: 100%;
  }
}

.icons {
  max-width: 175px;
  max-height: 175px;
  margin-right: 10px;

  @include lg {
    max-width: 220px;
    max-height: 220px;
  }

  @include xl {
    max-width: 275px;
    max-height: 275px;
  }

  @include xxs {
    max-width: 125px;
    max-height: 125px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.textTitleContainer {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 16px;
  text-align: left;
  line-height: 40px;

  @include lg {
    font-size: 30px;
  }

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    text-align: center;
    font-size: 20px;
  }
}

.footerDescription {
  font-size: 20px;
  letter-spacing: 2px;
  margin-left: 25px;
  margin-right: 25px;

  @include lg {
    font-size: 25px;
  }
  @include xl {
    font-size: 35px;
  }

  @include xxs {
    font-size: 15px;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.buttonSection {
  display: flex;
  padding-top: 40px;
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.textDescriptionContainer {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 24px;

  @include lg {
    font-size: 20px;
  }

  @include xl {
    font-size: 30px;
    line-height: 38px;
  }
}

.gradientBar {
  width: 100%;
  margin-bottom: 40px;
}

.reviewSectionGradientBarUpper {
  width: 100%;
  margin-top: 40px;
}

.reviewSectionGradientBarLower {
  width: 100%;
  margin-bottom: 40px;
}

.linkText {
  font-size: 30px;
  line-height: 1.6;

  @include lg {
    font-size: 36px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xms {
    font-size: 20px;
  }
}

.contentBox {
  height: 500px;
  width: 100%;
}

.dateText {
  margin-left: auto;

  @include xs {
    margin-left: 0;
  }
}

.sliderContainer {
  width: 100%;
  max-width: 1000px;
  padding: 0 15px;
  margin: 20px auto;

  @include xs {
    max-width: 700px;
    margin-top: 40px;
  }
}

.reviewSectionGradientBarUpper {
  margin-top: 20px;
  width: 100%;
  height: auto;
}

.reviewSectionGradientBarLower {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
