@import "shared/styles";

.VoucherPage {
  width: 100vw;
  min-height: 100vh;
  background-color: rgb(27, 13, 47);
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.iframe {
  // background-color: rgb(27, 13, 47);
}

.banner {
  display: flex;
  width: 100%;
  max-width: 1024px;
  // margin-top: 20px;
  margin-bottom: 40px;
}

.prompt {
  position: absolute;
  top: 100px;
  font-size: 30px;
  box-sizing: border-box;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
  }

  @include xxs {
    font-size: 15px;
  }
}
