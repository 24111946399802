@import "shared/styles";

.TwoOffersView {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  gap: 30px;
  box-sizing: border-box;

  @include xl {
    padding-bottom: 280px;
    gap: 100px;
  }
}
