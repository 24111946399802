@import "shared/styles";

.FAQSection {
  scroll-margin: $header-height;
  display: flex;
  justify-content: center;
  padding: 0 230px 0px 230px;
  width: 100vw;

  @include md {
    padding: 0 130px 0px 130px;
  }

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xs {
    padding: 0 30px;
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
    padding: 0 30px;
  }
}

.contentBox {
  max-width: 3000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
}

.title {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  margin-bottom: 20px;
  letter-spacing: 5px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 20px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.content {
  font-size: 20px;
  margin-bottom: 25px;
  padding: 0 20px;
  box-sizing: border-box;
  line-height: 1.4;

  @include xl {
    font-size: 40px;
  }

  @include xs {
    font-size: 16px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.contentItemContainer {
  width: 100%;
}

.contentQuestionContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 75px;
  background-color: rgba(83, 8, 126, 0.25);
  width: 100%;
  margin-bottom: 20px;
  padding: 5px 20px 5px 20px;
  box-sizing: border-box;
  gap: 10px;

  @include xs {
    gap: 5px;
  }
}

.contentQuestion {
  flex: 1;
  font-size: 30px;
  line-height: 1.4;
  text-align: left;

  @include xl {
    font-size: 50px;
  }

  @include xs {
    font-size: 18px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.contentQuestionContainerLighter {
  background-color: rgba(83, 8, 126, 0.5);
}

.faqArrowImage {
  transition: 0.5s;
  height: 21px;

  @include xs {
    height: 16px;
  }
}

.reversed {
  transform: rotate(180deg);
  transition: 0.5s;
}

.pixelBoxIcon{
  max-width: 400px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
}
