@import "./mixins.scss";

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
}

button {
  background: none;
  padding: 0;
  border: 0;
  outline: none;
}

a {
  text-decoration: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include xl {
    font-size: 50px;
  }
}

b {
  font-weight: bolder !important;
}
