@import "shared/styles";

.EmailStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 0 auto;

  @include xs {
    width: 100%;
  }

  @include lg {
    width: 1200px;
  }
}

.helloPrompt {
  font-size: 25px;
  margin-bottom: 15px;

  @include xs {
    font-size: 18px;
    margin-bottom: 0;
  }

  @include xms {
    font-size: 16px;
  }

  @include lg {
    font-size: 50px;
    margin-bottom: 30px;
  }
}

.prompt {
  font-size: 16px;
  margin-bottom: 5px;

  @include lg {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

.spinner {
  margin-top: 25px;

  @include xs {
    margin-top: 15px;
  }

  @include lg {
    margin-top: 50px;
  }
}

.button {
  margin-top: 25px;
  height: 38px;
  width: 150px;

  @include xs {
    height: 40px;
    width: 130px;
    margin-top: 15px;
  }

  @include lg {
    margin-top: 50px;
    height: 76px;
    width: 300px;
  }
}

.buttonText {
  font-size: 18px;
  letter-spacing: 1px;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}

.error {
  font-size: 16px;
  margin-top: 10px;

  @include lg {
    font-size: 28px;
    margin-top: 20px;
  }
}
