@import "shared/styles";

.text {
  font-family: $ff-primary;
  font-weight: $fw-regular;
  word-break: break-word;
  white-space: pre-line;
}

.text > * {
  font-family: $ff-primary;
  font-weight: $fw-regular;
  word-break: break-word;
  white-space: pre-line;
}

.bold {
  font-weight: $fw-bold;
}

.bold > * {
  font-weight: $fw-bold;
}

.white {
  color: $primary-white;
}

.white > * {
  color: $primary-white;
}

.whiteBordered {
  color: $primary-white;
  text-shadow:
    -1px 0 black,
    0 1px black,
    1px 0 black,
    0 -1px black;
}

.whiteBordered > * {
  color: $primary-white;
  text-shadow:
    -1px 0 black,
    0 1px black,
    1px 0 black,
    0 -1px black;
}

.pink {
  color: $secondary-pink;
}

.pink > * {
  color: $secondary-pink;
}

.lighterPink {
  color: $primary-lighter-pink;
}

.lighterPink > * {
  color: $primary-lighter-pink;
}

.darkBlue {
  color: $primary-dark-blue;
}

.darkBlue > * {
  color: $primary-dark-blue;
}

.red {
  color: $primary-red;
}

.red > * {
  color: $primary-red;
}

.blue {
  color: $primary-blue;
}

.blue > * {
  color: $primary-blue;
}

.purple {
  color: $primary-purple;
}

.purple > * {
  color: $primary-purple;
}

.lightBlue {
  color: $primary-light-blue;
}

.lightBlue > * {
  color: $primary-light-blue;
}

.lightGreen {
  color: $primary-light-green;
}

.lightGreen > * {
  color: $primary-light-green;
}

.orange {
  color: $primary-orange;
}

.orange > * {
  color: $primary-orange;
}

.green {
  color: white;
}

.secondary {
  font-family: $ff-secondary;
}

.secondary > * {
  font-family: $ff-secondary;
}

.black {
  color: $primary-black;
}

.black > * {
  color: $primary-black;
}

.alignCenter {
  text-align: center;
}

.alignCenter > * {
  text-align: center;
}

.gold {
  color: $primary-gold;
}

.gold > * {
  color: $primary-gold;
}

.errorRed {
  color: red;
}

.errorRed > * {
  color: red;
}
