$opacity-purple: rgba(25, 19, 53, 0.8);
$header-purpl: rgba(25, 19, 53);
$primary-white: white;
$primary-pink: #e921c0;
$secondary-pink: #e01eb8;
$primary-lighter-pink: #da9be3;
$primary-dark-blue: #2a1f4f;
$primary-red: #de4566;
$primary-blue: #522ab4;
$primary-purple: #8b81c8;
$primary-orange: #f2646a;
$primary-light-blue: #3acdba;
$primary-light-green: #39c0b3;
$primary-black: #0b0b0b;
$primary-gold: gold;
$primary-confirm-green: #34b233;
$primary-deny-red: #d92121;
