@import "shared/styles";

.integrationPage {
  width: 100%;
  background-image: linear-gradient(90deg, #100030 0%, #370b63 100%, #370b63 100%);
}

.container {
  margin-left: 100px;
  margin-right: 100px;
  
  @include xs {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}


.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.header {
  width: 100%;
  position: relative;
}

.integrationPagePicture {
  margin-top: auto;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  display: block;
}

.integrationPagePictureTextContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;

  @include lg {
    padding-right: 15px;
    padding-bottom: 15px;
  }

  @include xs {
    padding-right: 10px;
    padding-bottom: 10px;
  }
}

.integrationPagePictureText {
  font-size: clamp(12px, 3vw, 58px);
  letter-spacing: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.lineOne {
  margin-bottom: 20px; 
}

.lineTwo {
  margin-bottom: 0; 
}

.columnTitles {
  background-image: linear-gradient(90deg, #ff29cf 0%, #40e0cb 100%);
}

.flexItem {
  flex: 1;
  font-size: clamp(16px, 2vw, 22px);
  letter-spacing: 2px;
  word-break: auto-phrase;

  @include xs {
    flex: 1;
    font-size: clamp(12px, 2vw, 36px); 
    letter-spacing: 2px;
  }
}


.oddColumn {
  background-color: rgba(163, 96, 202, 0.149);
}

.evenColumn {
  background-color: rgba(83, 8, 126, 0.4);
}

.mobileTitle {
  font-size: clamp(30px, 5vw, 50px); 
}

.columnText{
  font-size: clamp(10px, 2vw, 28px);
  letter-spacing: 2px;
  line-height: clamp(20px,5vw,48px);
  
  @include xms {
    letter-spacing: 0px;
  }
}

.packageTable {
  width: 100%;
}

table {
  border-collapse: separate; 
  border-spacing: 0; 
}

thead {
  display: table-header-group;
}

tbody {
  display: table-row-group;
}

thead::after {
  content: "";
  display: table-row;
  height: 15px; 
}

.descriptionTitle {
  font-size: clamp(17px, 3vw, 35px);
  letter-spacing: 3px;
  margin-top: 30px;
  margin-bottom: 20px;

  @include xms {
    font-size: 12px;
  }
}

.descriptionText {
  font-size: clamp(14px, 2vw, 25px); 
  letter-spacing: 2px;

  @include xms {
    font-size: 10px;
  }
}

.gradientBar {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.addons {
  font-size: clamp(14px, 2vw, 25px); 
  letter-spacing: 2px;
  
  @include xms {
    font-size: 10px;
  }
}

.addonsTitle {
  font-size: clamp(17px, 3vw, 35px); 
  letter-spacing: 3px;
  margin-bottom: 20px;

  @include xms {
    font-size: 12px;
  }
}

.buttonSection {
  display: flex;
  padding-top: 40px;
  gap: 40px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

}

.linkText {
  font-size: 30px;
  line-height: 1.6;

  @include lg {
    font-size: 36px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xms {
    font-size: 20px;
  }
}

.dateText{
  width: 100%;
  text-align: right;
  margin-top: 20px;

  @include xs {
    text-align: center;
  }
}
