@import "shared/styles";

.Header {
  display: flex;
  width: 100%;
  background-color: $opacity-purple;
  height: $header-height;
  z-index: 100;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;

  @include xl {
    height: calc($header-height * 3);
  }

  @include lg {
    height: calc($header-height * 1.5);
  }

  @include md {
    height: $header-height;
  }

  @include xs {
    height: calc($header-height * 0.75);
  }

  @include xxs {
    height: calc($header-height * 0.5);
  }
}

.headerLogo {
  height: 35px;
  margin-left: 170px;
  cursor: pointer;

  @include xl {
    height: 100px;
  }

  @include lg {
    height: 60px;
  }

  @include xs {
    height: 24px;
    margin-right: 0;
    margin-left: 15px;
  }

  @include xxs {
    height: 20px;
  }
}

.hidden {
  visibility: hidden;
}

.iconsContainer {
  display: flex;
  gap: 15px;
  margin-right: 20px;

  @include xms {
    gap: 5px;
    margin-right: 5px;
  }
}

.headerLogoButton {
  display: flex;
  align-items: center;
}

.icon {
  height: 28px;
  width: 28px;
  cursor: pointer;

  @include xl {
    height: 50px;
    width: 50px;
  }

  @include xs {
    height: 24px;
    width: 24px;
  }

  @include xms {
    height: 20px;
    width: 20px;
  }
}

.resetLocationButtonContainer {
  height: 30px;
  margin-right: auto;
  margin-left: 16px;
  margin-bottom: 6px;

  @include lg {
    height: 50px;
  }

  @include xs {
    height: 20px;
    margin-left: 8px;
  }

  @include xms {
    margin-left: 4px;
  }
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  margin-right: 20px;

  @include xs {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 90;
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 0;
  }
}
