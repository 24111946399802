@import "shared/styles";

.PriceListSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  scroll-margin: $header-height;
  box-sizing: border-box;
  width: 100%;
  max-width: 3000px;

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
  }
}

.title {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  margin-bottom: 20px;
  letter-spacing: 5px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.content {
  font-size: 20px;
  margin-bottom: 20px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.priceList {
  display: flex;
  gap: 140px;
  justify-content: center;
  margin-bottom: 30px;

  @include md {
    gap: 40px;
  }

  @include xs {
    flex-direction: column;
  }
}

.priceListElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.priceListIcon {
  height: 84px;
  margin-bottom: 10px;
}

.priceListBiggerText {
  font-size: 34px;
  letter-spacing: 3px;
  line-height: 1;

  @include md {
    font-size: 24px;
  }
}

.priceListSmallerText {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 1;
  margin-bottom: 10px;

  @include md {
    letter-spacing: 1px;
    font-size: 14px;
  }
}

.priceListMediumText{
  font-size: 19px;
  letter-spacing: 2px;
  line-height: 1;
  margin-bottom: 10px;

  @include md {
    letter-spacing: 1px;
    font-size: 17px;
  }
}

.smallSeparator {
  width: 100%;
  height: 1px;
  background-color: #3acdba;
  margin: 10px 0;
}

.buyTicketButton {
  margin-bottom: 40px;

  @include xs {
    width: 250px;
  }

  @include xxs {
    width: 170px;
  }
}
.priceBanner{
  width: 800px;
  height: auto;
  margin-bottom: 40px;

  @include xs {
    width: 100%;
  }
}
