@import "shared/styles";

.link {
  position: relative;
  width: 280px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lg {
    width: 560px;
    height: 320px;
  }
}

.linkTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.1;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
  text-align: center;

  @include lg {
    font-size: 54px;
  }

  @include xs {
    font-size: 18px;
  }
}

.linkImage {
  object-fit: fill;
  height: 100%;
  max-width: 100%;
}

.linkDecoration {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 40px;
  background-image: linear-gradient(90deg, #ff8ad4 0%, #df1db7 100%);
  border-top-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 5px;

  @include lg {
    width: 100px;
    height: 80px;
    border-top-left-radius: 40px;
  }
}
