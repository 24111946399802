@import "shared/styles";

.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 2px;
  border: 1px solid $primary-white;
  border-radius: 10px;
  padding: 3px 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @include lg {
    padding: 20px 15px;
    border-radius: 15px;
  }

  @include xs {
    padding: 10px 10px;
    border-radius: 8px;
  }


  @include xxs {
    padding: 5px 5px;
  }
}

.button:hover .text {
  color: $primary-pink;
}

.button:hover .locationPin {
  filter: invert(20%) sepia(89%) saturate(6343%) hue-rotate(303deg) brightness(103%) contrast(86%);
}

.button:hover {
  border: 1px solid $primary-pink;
}

.locationPin {
  //filter: invert(20%) sepia(89%) saturate(6343%) hue-rotate(303deg) brightness(103%) contrast(86%);
  filter: invert(100%);
  height: 15px;
  width: 15px;
  margin-right: 5px;

  @include lg {
    height: 25px;
    width: 25px;
  }

  @include xs {
    height: 16px;
    width: 16px;
  }

  @include xms {
    height: 15px;
    width: 15px;
  }

  @include xxs {
    height: 13px;
    width: 13px;
  }
}

.text {
  font-size: 16px;

  @include md {
    font-size: 14px;
  }

  @include xl {
    font-size: 45px;
  }

  @include lg {
    font-size: 27px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xms {
    font-size: 12px;
  }

  @include xxs {
    font-size: 10px;
  }
}
