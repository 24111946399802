@import "shared/styles";

.Button {
  width: 360px;
  height: 75px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  border-radius: 36px;
  background-image: linear-gradient(90deg, #ff8ad4 0%, #df1db7 100%);

  @include xl {
    font-size: 50px;
    height: 150px;
    width: 700px;
    border-radius: 75px;
  }

  @include xs {
    width: 220px;
    padding: 10px 0;
    height: 55px;
  }

  @include xxs {
    width: 150px;
    padding: 5px 0;
    height: 40px;
  }
}

.Button:hover {
  background-image: linear-gradient(90deg, #44d5eb 0%, #0eb9a2 100%);
}

.text {
  font-size: 32px;
  z-index: 3;
  line-height: 1.3;
  letter-spacing: 3px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xxs {
    font-size: 15px;
  }
}
