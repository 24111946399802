@import "shared/styles";

.NewsletterSection {
  width: 100%;
  padding: 25px;
  margin-bottom: 60px;
  gap: 30px;

  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include xs {
    padding: 10px;
    margin-bottom: 30px;
    gap: 20px;
  }
}

.subTitle {
  font-size: 28px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 20px;
  }

  @include xms {
    font-size: 17px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.prompt {
  font-size: 20px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 2px;

  @include xl {
    font-size: 40px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 18px;
  }

  @include xms {
    font-size: 15px;
  }

  @include xxs {
    font-size: 14px;
  }
}

.error {
  font-size: 20px;
  margin-left: 20px;
  margin-top: 5px;

  @include xs {
    font-size: 18px;
    margin-left: 15px;
    margin-top: 3px;
  }
}

.signedTopContainer {
  display: flex;
  position: relative;
  padding: 0 60px;

  @include xl {
    padding: 0 100px;
  }

  @include xs {
    padding: 0 40px;
  }
}

.refreshIcon {
  position: absolute;
  top: 8px;
  right: 0px;
  height: 40px;

  @include xl {
    height: 70px;
    top: 20px;
  }

  @include xs {
    height: 30px;
    top: 3px;
  }

  @include xms {
    height: 25px;
  }
}
