@import "shared/styles";

.LanguageChooser {
  position: relative;
}

.flagButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.5s;
  position: absolute;
  top: 0;
}

.flagIcon {
  height: 30px;
  border-radius: 5px;

  @include lg {
    height: 60px;
    border-radius: 10px;
  }

  @include xs {
    height: 40px;
    border-radius: 7px;
  }

  @include xxs {
    height: 30px;
    border-radius: 5px;
  }
}

.whiteIconBorder {
  border: 2px solid white;
}

.blueIconBorder {
  border: 2px solid $primary-light-blue;
}
