@import "shared/styles";

.mobilePackages {
  background-color: #1e0e31;
  border-radius: 10px;

  .buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .packageButton {
      flex:1;
      height: auto;
      border-radius: 49px;
      font-size: 16px;
      cursor: pointer;
      background: #702c89;
      margin: 0 5px;
      transition: background-color 0.3s ease;

      &.activeButton {
        background-image: linear-gradient(90deg, #ff8ad4 0%, #df1db7 100%);
      }

      &:hover {
        background-color: #ab47bc;
      }
    }

    .packageButtonText {
      font-size: 18px;
      letter-spacing: 1px;

      @include xms {
        font-size: 15px;
      }
    }

  }

  

  .packageDetails {
    padding: 20px;
    border-radius: 10px;


    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 20px;
      margin-bottom: 20px;

      .tableRow {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px; 

        &:nth-child(odd) {
          background-color: rgba(83, 8, 126, 0.251);
        }

        &:nth-child(even) {
          background-color: rgba(83, 8, 126, 0.502);
        }

        .cellLabel {
          flex: 1;
          padding: 15px;
          text-align: left;
          color: #b39ddb;
          font-weight: bold;
        }

        .cellValue {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 5px;
          color: #b39ddb;
          font-weight: bold;
          overflow: hidden;
          text-align: right;
          white-space: nowrap;
        }

      
      }

      .footerRow {
        display: flex;
        justify-content: space-between;
        background-color: rgba(83, 8, 126, 0.8);
        margin-bottom: 20px; 
        height: auto; 
        padding: 15px 0; 
      }

      .columnFooter {
        flex: 1;
        padding-right: 15px;
        font-size: 18px;
        letter-spacing: 3px;

        
        @include xms {
          font-size: 12px;
        }
      }

      .columnFooterCell {
        flex: 0 0 50px;
        word-break: normal;
        font-size: 18px;
        letter-spacing: 3px;

@include xms {
  font-size: 12px;
}
      }
    }
  }


  .checkmarkText {
    font-size: 17px;
    letter-spacing: 3px;

    @include xms {
      font-size: 10px;
    }

  }
}


