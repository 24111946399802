@import "shared/styles";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 80px;
  height: 80px;
  border: 8px solid rgba(50, 205, 186, 0.3);
  border-top: 8px solid rgba(233, 33, 192);
  animation: spin 2s linear infinite;
  border-radius: 50%;

  @include xs {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(50, 205, 186, 0.3);
    border-top: 4px solid rgba(233, 33, 192);
  }
}
