@import "shared/styles";

.ReviewSection {
  scroll-margin: $header-height;
  display: flex;
  justify-content: center;
  padding: 0 50px 0px 50px;
  width: 100vw;

  @include md {
    padding: 0 10px;
  }

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
  }
}

.title {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  margin-bottom: 20px;
  letter-spacing: 5px;
  text-align: center;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.contentBox {
  width: 100%;
  height: 500px;
}
