@import "shared/styles";

.BirthdayPage {
  background-image: linear-gradient(90deg, #100030 0%, #370b63 100%, #370b63 100%);
}

.container {
  margin-left: 100px;
  margin-right: 100px;

  @include xs {
    margin-left: auto;
  margin-right: auto;
  }
}

.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.birthdayPagePictureContainer {
  width: 100%;
  position: relative;
}

.birthdayPagePicture {
  width: 100%;
  max-height: 500px; 
  object-fit: cover; 
  display: block;
}

.birthdayPagePictureTextContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
  
}

.birthdayPagePictureText {
  font-size: clamp(14px, 3vw, 58px);
  letter-spacing: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.lineOne {
  margin-bottom: 20px; 
}

.lineTwo {
  margin-bottom: 0; 
}

main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gradientBar {
  padding-top: 30px;
  padding-bottom: 20px;
}

.additionalPaymentsTitle {
  font-size: clamp(30px,1vw,50px);
  letter-spacing: 3px;
  padding-bottom: 30px;

  @include xms {
    font-size: 15px;
  }
}

.additionalPayments {
  font-size: clamp(20px,1vw,40px) ;
  letter-spacing: 2px ;

  @include xs {
    font-size: 20px ;
  letter-spacing: 2px ;
  }
  @include xms {
    font-size: 14px;
  }
}



.buttonSection {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  
}

.linkText {
  font-size: 30px;
  line-height: 1.6;

  @include lg {
    font-size: 36px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xms {
    font-size: 20px;
  }
}

.dateText{
  margin-left: auto;

  @include xs {
    margin-left: 0;
  }
}

