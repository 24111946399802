@import "shared/styles";

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MainPage {
  position: relative;
}

.home {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.homeVideo {
  flex: 100%;
  margin: -999vw calc(-8rem - 5%);
}

.homeBackground {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-dark-blue;

  @include xs {
    align-items: flex-start;
  }
}

.backgroundPlaceholder {
  height: 100vh;
  z-index: 1;
}

.homeContentContainer {
  height: 100vh;
  padding: 0 300px;
  box-sizing: border-box;
  width: 100%;
  max-width: 3000px;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 10;

  @include md {
    padding: 0 200px;
  }

  @include xs {
    padding: 0 15px;
  }
}

.separator {
  width: 100%;
  margin: 30px 0;

  @include xxs {
    margin: 5px 0;
  }
}

.homeContentTitle {
  font-size: 60px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  line-height: 1;
  letter-spacing: 5px;
  width: 100%;
  margin-bottom: 50px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    font-size: 30px;
  }

  @include xms {
    font-size: 25px;
  }

  @include xxs {
    font-size: 20px;
  }
}

.homeContent {
  font-size: 20px;
  margin-bottom: 30px;

  @include xl {
    font-size: 50px;
  }

  @include xs {
    font-size: 16px;
  }

  @include xxs {
    font-size: 12px;
  }
}

.buyTicketButton {
  @include xs {
    width: 250px;
  }

  @include xxs {
    width: 170px;
  }
}

.mainContent {
  background-color: rgba(25, 18, 53);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 200px;
  box-sizing: border-box;
  position: relative;
  gap: 100px;
  padding-bottom: 10px;

  @include xs {
    padding: 0 5px 5px 5px;
    gap: 50px;
  }

  @include xxs {
    padding: 0 2px 50px 2px;
  }
}

.firstShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.secondShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.backgroundLowerDecorator {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  pointer-events: none;
}

.spinner {
  border: 20px solid rgba(50, 205, 186, 0.3);
  border-top: 20px solid rgba(233, 33, 192);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  animation: spin 2s linear infinite;
  margin-top: $header-height;

  @include xs {
    width: 100px;
    height: 100px;
    border: 12px solid rgba(50, 205, 186, 0.3);
    border-top: 12px solid rgba(233, 33, 192);
  }
}

.hide {
  animation-name: disappear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.iframeContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.relativeContainer {
  position: relative;
}

.iframeBlend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 18, 53, 0.5);
  mix-blend-mode: multiply;
}

.resetLocationButtonContainer {
  width: 200px;
  height: 50px;
  margin-top: 20px;

  @include lg {
    width: 280px;
    height: 65px;
  }

  @include xs {
    width: 160px;
    height: 40px;
    margin-top: 15px;
  }

  @include xxs {
    width: 120px;
    height: 25px;
    margin-top: 5px;
  }
}

.resetLocationButton {
  border-radius: 15px;

  @include lg {
    border-radius: 40px;
  }

  @include xs {
    border-radius: 10px;
  }

  @include xxs {
    border-radius: 15px;
  }
}

.resetLocationButtonText {
  font-size: 20px;

  @include lg {
    font-size: 30px;
  }

  @include xs {
    font-size: 18px;
  }

  @include xxs {
    font-size: 12px;
  }
}

.resetLocationButtonPin {
  height: 20px;
  width: 20px;

  @include lg {
    height: 28px;
    width: 28px;
  }

  @include xs {
    height: 15px;
    width: 15px;
  }

  @include xxs {
    height: 12px;
    width: 12px;
  }
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  display: none;

  @include xs {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 90;
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 0;
  }
}

.recaptchaContainer {
  position: fixed;
  left: 0;
  height: 100vh;
  height: 100dvh;
  z-index: 90;
  display: flex;
  align-items: flex-end;
  padding: 0 20px 20px 0;
}

.warsawBaner {
  max-width: 750px;
  max-height: auto;
}
