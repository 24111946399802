@import "shared/styles";

.LocationInProgressPage {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(
    90deg,
    #100030 0%,
    #370b63 100%,
    #370b63 100%
  );
  padding: 2px;
  box-sizing: border-box;

  align-items: center;
  justify-content: center;
}

.mainContent {
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.prompt {
  font-size: 26px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  text-align: center;

  @include xl {
    font-size: 56px;
  }

  @include xs {
    font-size: 18px;
  }

  @include xxs {
    font-size: 14px;
  }
}

.iconsContainer {
  z-index: 1;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @include xxs {
    margin: 0;
    gap: 5px;
  }
}

.icon {
  height: 38px;
  width: 38px;
  cursor: pointer;

  @include xl {
    height: 60px;
    width: 60px;
  }

  @include xs {
    height: 28px;
    width: 28px;
  }

  @include xxs {
    height: 20px;
    width: 20px;
  }
}

.animatedPixel {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 30px;
  left: 30px;
  animation-name: spinAndChangeColor;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  z-index: 10;
}

@keyframes spinAndChangeColor {
  0% {
    transform: rotate(0deg);
    background: #e921c0;
  }
  50% {
    transform: rotate(180deg);
    background: #3acdba;
  }
  100% {
    transform: rotate(360deg);
    background: #e921c0;
  }
}

.boardImage {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  opacity: 0.3;
}

.headerLogo {
  cursor: pointer;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;

  height: 35px;
  width: 150px;

  @media (max-height: 500px) {
    top: 10px;
  }

  @include lg {
    height: 60px;
    width: 200px;
    top: 120px;
  }
}

.locationsButtonContainer {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  height: 35px;
  width: 150px;

  @media (max-height: 500px) {
    top: 50px;
  }

  @include lg {
    top: 200px;
    height: 60px;
    width: 220px;
  }

  @include xms {
    width: 120px;
    height: 30px;
  }
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  z-index: 90;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(calc(-50% + 120px));

  @media (max-height: 500px) {
    top: 10px;
  }

  @include lg {
    top: 115px;
    transform: translateX(calc(-50% + 180px));
  }

  @include xs {
    left: unset;
    top: unset;
    transform: unset;
    position: fixed;
    right: 0;
    height: 100vh;
    height: 100dvh;
    z-index: 90;
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 0;
  }
}
