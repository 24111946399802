@import "shared/styles";

.DescriptionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  scroll-margin: $header-height;
  box-sizing: border-box;
  width: 100%;
  max-width: 3000px;
}

.content {
    font-size: 20px;
    margin-bottom: 20px;
  
    @include xl {
      font-size: 40px;
    }
  
    @include xxs {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }