@import "shared/styles";

.ThankYouStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 0 auto;

  @include xs {
    width: 100%;
  }

  @include lg {
    width: 1200px;
  }
}

.prompt {
  font-size: 18px;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}
