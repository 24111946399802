@import "shared/styles";

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Pixel {
  position: absolute;
  background: $primary-light-blue;
  transform: rotate(45deg);
  filter: blur(2px);
  animation: rotate 5s infinite linear;
}
