@import "shared/styles";

.clipPath:hover {
  transition: 1s;
  transform: translate3d(0, 5px, 0);
}

.jobOffer {
  transition: 1s;
  min-width: 280px;
  width: 280px;
  height: 280px;
  background: #43315b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;

  @include xl {
    min-width: 340px;
    width: 500px;
    height: 500px;
  }

  @include xs {
    min-width: 200px;
    width: 200px;
    height: 200px;
  }

  @include xms {
    min-width: 150px;
    width: 150px;
    height: 150px;
  }
}

.clipPath {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transform: translateZ(0);
}

.jobOfferWithPadding {
  padding: 0 15px;
}

.link {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  @include xs {
    gap: 5px;
  }
}

.jobTitle {
  font-size: 22px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  @include xl {
    font-size: 40px;
    height: 90px;
  }

  @include xs {
    font-size: 20px;
    height: 40px;
  }

  @include xms {
    font-size: 14px;
    height: 30px;
  }
}

.jobCity {
  font-size: 20px;
  height: 36px;
  line-height: 1.1;
  margin-top: 15px;

  @include xl {
    font-size: 35px;
    margin-top: 30px;
  }

  @include xs {
    font-size: 16px;
  }

  @include xms {
    font-size: 10px;
  }
}

.jobAdditionalInfo {
  font-size: 18px;
  height: 36px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
  }
}
