@import "shared/styles";

.NewsletterInput {
  padding: 0px 5px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  @include xl {
    max-width: 800px;
  }
}

.input {
  border-radius: 30px;
  background-color: $primary-light-blue;
  border: 2px solid $primary-pink;
  outline: none;
  font-family: $ff-secondary;
  color: $primary-white;
  box-sizing: border-box;
  padding: 15px 20px;
  font-size: 22px;

  @include xl {
    padding: 25px 40px;
    font-size: 40px;
    border-radius: 50px;
  }

  @include xs {
    padding: 10px 15px;
    font-size: 18px;
  }
}

.input::placeholder {
  color: $primary-white;
  opacity: 1;
}

.errorInput {
  border: 2px solid red;
}

.error {
  font-size: 18px;
  margin-left: 20px;
  margin-top: 5px;

  @include xs {
    font-size: 16px;
    margin-left: 15px;
    margin-top: 3px;
  }
}
