@import "shared/styles";

@keyframes show-toast {
	0% {
		opacity: 0;
		transform: translate(-50%, calc($header-height - 30px));
	}
	20% {
		opacity: 1;
		transform: translate(-50%, calc($header-height + 30px));
	}
	80% {
		opacity: 1;
		transform: translate(-50%, calc($header-height + 30px));
	}
	100% {
		opacity: 0;
		transform: translate(-50%, calc($header-height - 30px));
	}
}

@keyframes show-toast-mobile {
	0% {
		opacity: 0;
		transform: translate(-50%, calc($header-height * 0.5 - 10px));
	}
	20% {
		opacity: 1;
		transform: translate(-50%, calc($header-height * 0.5 + 10px));
	}
	80% {
		opacity: 1;
		transform: translate(-50%, calc($header-height * 0.5 + 10px));
	}
	100% {
		opacity: 0;
		transform: translate(-50%, calc($header-height * 0.5 - 10px));
	}
}

.Toast {
	position: fixed;
	top: 0;
	width: 700px;
	padding: 15px 10px;
	background-color: rgba(58, 205, 186, 0.7);
	border-radius: 10px;
	z-index: 1000;
	left: 50%;
	transform: translate(-50%, calc($header-height - 30px));
	opacity: 0;
	animation-name: show-toast;
	animation-duration: 5s;
	animation-fill-mode: forwards;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	@include xs {
		width: 400px;
	}

	@include xxs {
		width: 200px;
		padding: 5px 3px;
		transform: translate(-50%, calc($header-height * 0.5 - 10px));
		opacity: 0;
		animation-name: show-toast-mobile;
		animation-duration: 5s;
		animation-fill-mode: forwards;

	}
}

.failed {
  background-color: rgba(255, 0, 0, 0.7);
}

.text {
	font-size: 20px;

	@include xs {
		font-size: 16px;
	}

	@include xxs {
		font-size: 12px;
	}
}
