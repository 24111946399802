@import "shared/styles";

.img {
    width: 40px;
    height: auto;

  
    @include xms {
      width: 21px;
      height: 21px;
    }
  }
  
  .text {
    font-size: 30px;
    word-break: normal;

    @include xms{
      font-size: 20px;
    }
  }
