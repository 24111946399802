@import "shared/styles";

.FlipCountdown {
  display: flex;
  gap: 50px;
  position: relative;
  justify-content: center;

  @include lg {
    gap: 100px;
  }

  @include xs {
    gap: 20px;
  }

  @include xxs {
    gap: 10px;
  }
}

.dateSection {
  display: flex;
}

.timeSection {
  display: flex;
  gap: 10px;

  @include lg {
    gap: 20px;
  }

  @include xs {
    gap: 6px;
  }

  @include xms {
    gap: 4px;
  }
}

.partsContainer {
  display: flex;
  flex-direction: column;
}

.rotatorsContainer {
  display: flex;
  gap: 10px;

  @include lg {
    gap: 20px;
  }

  @include xs {
    gap: 6px;
  }

  @include xms {
    gap: 4px;
  }
}

.rotator {
  transition: 0.3s;
  height: 60px;
  width: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e921c0;

  @include lg {
    height: 120px;
    width: 120px;
  }

  @include xs {
    height: 37px;
    width: 37px;
  }

  @include xms {
    height: 30px;
    width: 30px;
  }
}

.dateRotator {
  background-color: #3acdba;
}

.rotating {
  transform: rotateZ(-180deg);
  transition: 0.3s;
}

.text {
  font-size: 32px;
  text-align: center;

  @include lg {
    font-size: 64px;
  }

  @include xs {
    font-size: 25px;
  }

  @include xms {
    font-size: 20px;
  }
}

.label {
  font-size: 28px;
  text-align: center;

  @include lg {
    font-size: 56px;
  }

  @include xs {
    font-size: 17px;
  }

  @include xms {
    font-size: 13px;
  }
}
