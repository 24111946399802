@import "shared/styles";

.Checkbox {
  display: flex;
  gap: 4px;

  @include xs {
    gap: 2px;
  }

  @include lg {
    gap: 8px;
    width: 20px;
  }
}

.input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.label {
  font-size: 14px;
  min-width: max-content;

  @include lg {
    font-size: 28px;
  }
}
