@import "shared/styles";

.contentContainer {
  display: flex;
  gap: 50px;
  width: 100%;
  height: 100%;

  @include xs {
    flex-direction: column;
    gap: 50px;
  }
}

.groupContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.contentItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 10px;
}

.border {
  object-fit: fill;
  height: 100%;
  max-width: 100%;
}

.lowerQuotationMark {
  position: absolute;
  bottom: 0;
  right: 30px;
  transform: translateY(-70%);
}

.upperQuotationMark {
  position: absolute;
  top: 0;
  left: 30px;
  transform: translateY(-50%);
}

.reviewText {
  position: absolute;
  top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  letter-spacing: 2px;
  line-height: 1.1;
}

.reviewAuthor {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
