@import "shared/styles";

.table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0 10px; 
  background-color: transparent;
}

.columnHeader {
  padding: 8px;
  font-size: 30px;
  letter-spacing: 4px;
  background: none; 
  word-break: auto-phrase;
}

.cellText {
  padding: 8px;
  font-size: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.columnFooter, 
.columnFooterCellText {
  background-color: rgba(83, 8, 126, 0.8);
}

.columnFooter {
  font-size: 20px;
  letter-spacing: 2px;
  text-align: right;
}

.columnFooterCellText {
  font-size: 22px;
  letter-spacing: 2px;
}

.headerRow {
  height: 75px;
  background-image: linear-gradient(90deg, #ff29cf 0%, #40e0cb 100%);
}

.column1 {
  background: linear-gradient(135deg, rgba(83, 8, 126, 0.251) 0%, rgba(106, 13, 173, 0.251) 100%);
  min-width: 50px;
  max-width: 60px;
}

.column2 {
  background-color: rgba(241, 119, 249, 0.200);
  min-width: 100px;
  max-width: 100px;
}

.column3 {
  background-color: rgba(56, 217, 236, 0.302);
  min-width: 85px;
  max-width: 75px;
}

.column4 {
  background-color: rgba(241, 119, 249, 0.200);
  min-width: 70px;
  max-width: 75px;
}

.table tr:nth-child(even) .column1 {
  background: linear-gradient(135deg, rgba(83, 8, 126, 0.502) 0%, rgba(106, 13, 173, 0.502) 100%);
}

.table tr:nth-child(even) .column2 {
  background-color: rgba(241, 119, 249, 0.200);
}

.table tr:nth-child(even) .column3 {
  background-color: rgba(56, 217, 236, 0.502);
}

.table tr:nth-child(even) .column4 {
  background-color: rgba(241, 119, 249, 0.200);
}

