@import "shared/styles";

.BookingPage {
  width: 100vw; 
  min-height: 100vh;
  background-color: rgb(27, 13, 47);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.languageChooserContainer {
  display: none;
}

.temporaryTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.technicalBreakText {
  font-size: clamp(12px, 2vw, 42px);

  @include xs {
    font-size: clamp(10px, 5vw, 20px);
  }

  @include xl {
    font-size: clamp(42px, 1vw, 72px);
  }
}

.banner{
  width: 100%;
  max-width: 700px;
  height: auto;
  margin-top: 10vh;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
}
