@import "shared/styles";

.ContactSection {
  scroll-margin: $header-height;
  display: flex;
  justify-content: center;
  gap: 100px;
  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
  }
}

.contentBox {
  max-width: 3000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 100px;
  box-sizing: border-box;

  @include xs {
    padding: 15px;
  }
}

.separator {
  width: 100%;
  margin: 30px 0;

  @include xs {
    margin: 15px 0;
  }
}

.title {
  font-size: 40px;
  margin-bottom: 20px;
  letter-spacing: 5px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 22px;
  margin-bottom: 30px;
  box-sizing: border-box;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
  }

  @include xxs {
    margin-bottom: 5px;
  }
}

.content {
  font-size: 16px;
  margin-bottom: 20px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.contact {
  display: flex;
  width: 100%;
  font-size: 24px;

  @include xl {
    font-size: 48px;
  }

  @include md {
    font-size: 16px;
  }

  @include xxs {
    font-size: 14px;
    line-height: 1.1;
    flex: 1;
  }
}

.contactRow {
  display: flex;
  width: 280px;
  justify-content: space-between;

  @include xl {
    width: 800px;
  }
  
}

.contactColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include xs {
   margin-right: 15px; 
  }
}

.contactContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 30px;
  margin-top: 15px;
  @include xl {
    gap: 100px;
  }

  @include xs {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
  }

  @include xxs {
    gap: 10px;
    margin-bottom: 10px;
  }
}

.contactEntry {
  display: flex;
  gap: 12px;
  align-items: center;
  @include xxs {
    gap: 5px;
  }
}

.phoneIcon {
  height: 24px;
  color: black;

  @include xl {
    height: 48px;
  }

  @include xxs {
    height: 16px;
  }
}

.addressIcon {
  height: 25px;

  @include xl {
    height: 50px;
  }

  @include xxs {
    height: 16px;
    visibility: hidden;
  }
}

.mailIcon {
  height: 22px;

  @include xl {
    height: 44px;
  }

  @include xxs {
    height: 14px;
  }
}
