@import "shared/styles";

.jobOfferContainer {
  filter: drop-shadow(0px 20px 0px rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0px 20px 0px rgba(0, 0, 0, 0.2));
  transition: 1s;
}

.jobOfferContainer:hover {
  filter: drop-shadow(0px 10px 0px rgba(58, 205, 186, 0.2));
  transition: 1s;
}

.jobOffer {
  min-width: 280px;
  width: 280px;
  height: 280px;
  background: #43315b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;

  @include xl {
    min-width: 340px;
    width: 500px;
    height: 500px;
  }

  @include xs {
    min-width: 200px;
    width: 200px;
    height: 200px;
  }

  @include xms {
    min-width: 150px;
    width: 150px;
    height: 150px;
  }
}

.clipPath {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.jobOfferWithPadding {
  padding: 0 15px;
}

.link {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  @include xs {
    gap: 5px;
  }
}

.jobTitle {
  font-size: 26px;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  @include xl {
    font-size: 50px;
    height: 90px;
  }

  @include xs {
    font-size: 16px;
    height: 40px;
  }

  @include xms {
    font-size: 12px;
    height: 30px;
  }
}

.jobCity {
  font-size: 20px;
  height: 36px;
  line-height: 1.1;
  margin-top: 10px;

  @include xl {
    font-size: 35px;
  }

  @include xs {
    font-size: 16px;
  }

  @include xms {
    font-size: 10px;
    margin-top: 7px;
  }
}

.jobAdditionalInfo {
  font-size: 18px;
  height: 36px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
  }
}
