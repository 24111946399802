@import "shared/styles";

.CancelOverlay {
  position: fixed;
  top: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 110;
}

.popup {
  position: relative;
  z-index: 120;
  display: flex;
  flex-direction: column;
  background-color: $primary-dark-blue;
  box-sizing: border-box;
  border-radius: 36px;
  padding: 25px 50px;
  gap: 25px;
  margin: 0 20px;

  @include xl {
    padding: 50px 50px;
  }

  @include xs {
    padding: 25px 25px;
  }

  @include xms {
    padding: 15px 15px;
    gap: 20px;
  }
}

.text {
  font-size: 26px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xms {
    font-size: 15px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 25px;

  @include xl {
    gap: 50px;
  }

  @include xms {
    gap: 15px;
  }
}

.button {
  opacity: 0.6;
  width: auto;
  height: auto;
  padding: 10px 80px;

  @include xl {
    padding: 10px 160px;
  }

  @include xs {
    padding: 5px 40px;
  }
}

.button:hover {
  opacity: 1;
}

.confirmButton {
  background: $primary-confirm-green;
}

.confirmButton:hover {
  background: $primary-confirm-green;
}

.denyButton {
  background: $primary-deny-red;
}

.denyButton:hover {
  background: $primary-deny-red;
}
