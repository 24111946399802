@import "shared/styles";

.CooperationSection {
  scroll-margin: $header-height;
  display: flex;
  width: 100%;
  max-width: 3000px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;

  @include xs {
    padding: 0 15px;
  }

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
  }
}

.title {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  margin-bottom: 20px;
  letter-spacing: 5px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 20px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.content {
  font-size: 16px;
  margin-bottom: 20px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.linksContainer {
  display: flex;
  gap: 40px;

  @include xs {
    flex-direction: column;
  }
}
