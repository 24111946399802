@import "shared/styles";

.GoogleMaps {
  height: 350px;
  width: 100%;
  border: 0;

  @include xl {
    height: 700px;
  }

  @include xs {
    height: 180px;
  }
}
