@import "shared/styles";

.RadioButton {
  display: flex;
  gap: 4px;
  align-items: center;

  @include lg {
    gap: 8px;
  }
}

.input {
  cursor: pointer;

  @include lg {
    width: 20px;
  }
}

.label {
  cursor: pointer;
  font-size: 14px;

  @include lg {
    font-size: 28px;
  }
}
