@import "shared/styles";

@keyframes arrive {
  0% {
    transform: translateX(-250px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes disappear {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}

@keyframes hideMenu {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.MobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.menu {
  height: 100%;
  width: 250px;
  background-color: $primary-dark-blue;
  display: flex;
  flex-direction: column;

  @include xms {
    width: 220px;
  }
}

.mobileMenuCloseOverlay {
  flex: 1;
  height: 100%;
}

.visible {
  visibility: visible;
}

.mobileBar {
  height: 100%;
}

.menuHeader {
  height: $header-height;
  background-color: rgba(25, 19, 53);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.arrive {
  animation-name: arrive;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.disappear {
  animation-name: disappear;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.delayedHidden {
  animation-name: hideMenu;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.menuItemsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.sectionText {
  font-size: 28px;
  text-align: left;
}

.bookText {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}

.selectedSectionText {
  color: $primary-pink !important;
}

.link {
  display: flex;
  gap: 2px;
  cursor: pointer;
}

.bookLink {
  margin-top: 30px;
}

.link:hover .sectionText {
  color: $primary-pink;
}

.selectedLinkMark {
  height: 12px;
  width: 12px;
}

.hidden {
  visibility: hidden;
}

.bottomContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: fit-content;
  gap: 20px;
}

.iconsContainer {
  max-width: fit-content;
  display: flex;
  align-items: flex-end;
  gap: 15px;
}

.mobileClose {
  display: flex;
  align-items: center;
  height: 100%;
}
