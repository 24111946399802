@import "shared/styles";

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contactSectionTitle {
  font-size: 30px;
  letter-spacing: 3px;

  @include lg {
    font-size: 40px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xms {
    font-size: clamp(13px, 2vw, 22px);
  }
}
.contactSectionSubtitle {
  font-size: 25px;
  letter-spacing: 3px;

  @include lg {
    font-size: 34px;
  }

  @include xs {
    font-size: 19px;
  }

  @include xms {
    font-size: clamp(10px, 2vw, 19px);
  }
}

.formInputsContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 900px;

  @include xs {
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    margin-bottom: 10px;
  }
}

.formColumOneContainer,
.formColumTwoContainer {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  gap: 10px;
}

.formTextInputContainer {
  padding: 0 10px;
}

.form {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submitButton {
  width: 600px;
  box-sizing: border-box;
  height: 70px;
  margin-top: 30px;
  margin-bottom: 20px;

  @include lg {
    width: 700px;
    height: 80px;
    margin-top: 40px;
  }

  @include xs {
    width: 390px;
    height: 70px;
  }

  @include xms {
    width: 296px;
    height: 60px;
    margin-bottom: 10px;
  }
}

.submitButtonText {
  @include lg {
    font-size: 36px;
  }

  @include xs {
    letter-spacing: 1px;
    padding: 5px 0;
  }

  @include xms {
    font-size: 20px;
  }
}

.connectionErrorText {
  font-size: 18px;
  margin-top: 10px;

  @include lg {
    font-size: 20px;
  }

  @include xs {
    font-size: 16px;
  }
}

.thankYouContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thankYouTextBigger {
  font-size: 24px;

  @include lg {
    font-size: 30px;
  }

  @include xs {
    font-size: 22px;
  }
}
