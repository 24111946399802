@import "shared/styles";

.Footer {
	display: flex;
	height: 100px;
	align-items: flex-end;
	background-color: $primary-dark-blue;
	overflow-y: hidden;

	@include xl {
		height: 200px;
	}

	@include lg {
		height: 150px;
	}

	@include xs {
		height: 170px;
	}
}

.internalFooter {
	display: flex;
	width: 100%;
	height: 80px;
	align-items: center;
	justify-content: space-around;

	@include xl {
		height: 160px;
	}

	@include lg {
		height: 120px;
	}

	@include xs {
		flex-direction: column-reverse;
		height: 170px;
	}
}

.leftColumn {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 10px;

	@include xl {
		gap: 20px;
	}

	@include lg {
		height: 15px;
	}

	@include xs {
		gap: 20px;
		height: auto;
		padding: 0 3px;
		box-sizing: border-box;
	}

	@include xxs {
		gap: 7px;
	}
}

.rightColumn {
	display: flex;
	height: 100%;
	align-items: center;

	@include xs {
		flex-direction: row-reverse;
		width: 100%;
		justify-content: center;
		gap: 20px;
		height: auto;
		padding: 0 3px;
		box-sizing: border-box;
	}

	@include xxs {
		gap: 7px;
	}
}

.logo {
	height: 30px;

	@include xl {
		height: 70px;
	}

	@include lg {
		height: 45px;
	}

	@include xxs {
		height: 15px;
	}
}

.text {
	font-size: 14px;
	white-space: pre-line;
	line-height: 1;

	@include xl {
		font-size: 30px;
	}

	@include lg {
		font-size: 20px;
	}

	@include xxs {
		font-size: 8px;
	}
}

.link {
	font-size: 14px;
	cursor: pointer;

	@include xl {
		font-size: 30px;
	}

	@include lg {
		font-size: 20px;
	}

	@include xxs {
		font-size: 10px;
	}
}

.link:hover {
	color: $primary-pink;
}


.iconsContainer {
	display: flex;
	gap: 15px;
	align-items: center;
	margin-left: 20px;

	@include xxs {
		margin: 0;
		gap: 5px;
	}
}

.iconLink {
	display: flex;
}

.icon {
	height: 28px;
	width: 28px;
	cursor: pointer;

	@include xl {
		height: 60px;
		width: 60px;
	}

	@include xxs {
		height: 20px;
		width: 20px;
	}
}

.arrow {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	right: 40px;
	height: 100px;
	cursor: pointer;

	@include xl {
		height: 200px;
		right: 80px;
	}

	@include xs {
		right: 0;
		margin-top: 15px;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100px;
	}
}

.arrowContainer {
	width: 180px;
	height: 100%;
	position: relative;

	@include xl {
		width: 360px;
	}

	@include xs {
		display: none;
	}
}

.smallSquare {
	position: absolute;
	top: 50%;
	right: 0;
	height: 30px;
	transform: translate(0, -50%);

	@include xl {
		height: 60px;
	}

	@include xs {
		right: 0;
		left: calc(50% + 30px);
		transform: translate(calc(-50% + 30px), calc(-50% - 15px));
		height: 30px;
	}
}

.mediumSquare {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 50px;
	transform: translate(0, 50%);

	@include xl {
		height: 100px;
	}

	@include xs {
		right: 0;
		left: calc(50% - 30px);
		transform: translate(calc(-50% - 30px), calc(-50% + 70px));
		height: 50px;
	}
}

.policiesContainer {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 30px;

	@include xs {
		flex-direction: column;
		gap: 3px;
		justify-content: center;
		line-height: 1;
		text-align: center;
	}
}

.mobileArrowContainer {
	display: none;

	@include xs {
		display: block;
		width: 100%;
		height: 50px;
		position: relative;
	}
}
