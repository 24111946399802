@import "shared/styles";

.UserDataFormPage {
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.upperContainer {
  display: flex;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  justify-content: center;

  @include xms {
    margin-bottom: 15px;
  }

  @include xs {
    width: 100%;
  }

  @include lg {
    width: 1200px;
    margin-bottom: 80px;
  }
}

.logo {
  @include xms {
    width: 120px;
  }

  @include lg {
    width: 300px;
  }
}

.upperButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.backArrow {
  transform: rotate(90deg);

  @include xms {
    height: 15px;
  }

  @include lg {
    height: 40px;
  }
}

.refreshIcon {
  height: 35px;

  @include xms {
    height: 25px;
  }

  @include lg {
    height: 70px;
  }
}

.stepContainer {
  box-sizing: border-box;
  width: 100%;
}

.languageChooserContainer {
  position: absolute;
  right: 0;
}

.languageChooser {
  position: relative !important;
  top: 0;
  right: 0;
}
