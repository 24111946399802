@import "shared/styles";

.FourOffersView {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 140px;
  padding-right: 140px;
  gap: 30px;
  box-sizing: border-box;
  position: relative;

  @include xl {
    padding-bottom: 320px;
    gap: 100px;
  }
}

.firstAbsoluteJobOffer {
  position: absolute !important;
  top: 160px;

  @include xl {
    top: 320px;
  }
}

.secondAbsoluteJobOffer {
  position: absolute !important;
  top: 160px;
  left: calc(50% + 100px);

  @include xl {
    top: 320px;
    left: calc(50% + 280px);
  }
}
