@import "shared/styles";

.CareerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  scroll-margin: $header-height;
  width: 100%;
  max-width: 3000px;
  padding-bottom: 70px;
  position: relative;

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xs {
    padding-bottom: 15px;
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
    padding-bottom: 10px;
  }
}

.title {
  font-size: 40px;
  margin-bottom: 20px;
  letter-spacing: 5px;
  filter: drop-shadow(0 0 7.5px #5a1eac);

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 30px;
  margin-bottom: 60px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 20px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.content {
  font-size: 16px;
  margin-bottom: 20px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.buttonsContainer {
  position: absolute;
  width: 100vw;
  height: 100%;
}

.nextPageButton {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50%));
  right: 20px;
  z-index: 10000;
}

.prevPageButton {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50%));
  left: 20px;
  z-index: 10000;
}
