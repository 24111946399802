@import "shared/styles";

.LocationInProgressPage {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(
    90deg,
    #100030 0%,
    #370b63 100%,
    #370b63 100%
  );
  padding: 2px;
  box-sizing: border-box;

  align-items: center;
  justify-content: center;

  @include xs {
    padding-bottom: 80px;
  }
}

.mainContent {
  z-index: 10;
  display: flex;
  flex-direction: column;

  @include xs {
    margin-top: 100px;
  }
}

.bigPrompt {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  text-align: center;

  @include lg {
    font-size: 70px;
  }

  @include xs {
    font-size: 30px;
  }

  @include xms {
    font-size: 20px;
  }
}

.cooperationContainer {
  margin-top: 50px;
}

.mediumPrompt {
  font-size: 28px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  text-align: center;

  @include lg {
    font-size: 40px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xms {
    font-size: 16px;
  }
}

.prompt {
  font-size: 22px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  text-align: center;

  @include lg {
    font-size: 38px;
  }

  @include xs {
    font-size: 16px;
  }

  @include xms {
    font-size: 14px;
  }
}

.boardImage {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  opacity: 0.3;
}

.headerLogo {
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 20;
  height: 35px;
  width: 150px;

  @include lg {
    top: 15px;
    height: 60px;
    width: 200px;
  }

  @include xs {
    width: 120px;
    height: 30px;
  }
}

.locationsButtonContainer {
  position: absolute;
  top: 10px;
  left: 190px;
  z-index: 20;
  height: 35px;
  width: 150px;

  @include lg {
    left: 250px;
    height: 60px;
    width: 220px;
  }

  @include xs {
    left: 160px;
    width: 130px;
    height: 30px;
  }
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  z-index: 90;
  position: absolute;
  top: 13px;
  left: 370px;

  @include lg {
    left: 510px;
  }

  @include xs {
    position: fixed;
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
    z-index: 90;
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 0;
  }
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 10px;

  @include xs {
    flex-direction: column;
  }
}

.secondaryContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.newsletterInputContainer {
  position: relative;
  margin-top: 50px;
}
