@import "shared/styles";

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.LocationsPage {
  width: 100vw;
  min-height: 100vh;
  width: 100dvw;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  z-index: 90;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(calc(-50% + 120px));

  @include lg {
    top: 35px;
    transform: translateX(calc(-50% + 180px));
  }

  @include xs {
    position: fixed;
    left: unset;
    top: unset;
    transform: unset;
    right: 0;
    bottom: 0;
    z-index: 90;
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 0;
  }
}

.videoBackground {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-dark-blue;

  @include xs {
    align-items: flex-start;
  }
}

.hide {
  animation-name: disappear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.iframeContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary-dark-blue;
}

.iframeBlend {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 18, 53, 0.1);
  mix-blend-mode: multiply;
}

.iframe {
  flex: 100%;

  @include lg {
    object-fit: contain;
    margin: 0;
  }
}

.iframeWithMargin {
  margin: -999vw calc(-8rem - 5%);
}

.buttonsContainer {
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 40px 40px 100px 40px;
  box-sizing: border-box;
  gap: 60px;
  margin: auto 0;
  margin-top: 100px;
  // transform: translateY(-50%);

  @include lg {
    padding: 80px;
    top: 200px;
  }

  @include xs {
    padding: 10px;
    gap: 40px;
  }
}

.locationRow {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;

  @include xs {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
}

.prompt {
  font-size: 42px;
  line-height: 1.5;
  background: rgba(58, 205, 186, 0.5);
  filter: drop-shadow(0 0 7.5px #5a1eac);
  padding: 10px 25px;
  border-radius: 36px;
  margin-bottom: 40px;
  text-align: center;
  margin-bottom: auto;

  @include lg {
    font-size: 60px;
    padding: 20px 50px;
    border-radius: 50px;
  }

  @include xs {
    font-size: 30px;
    padding: 5px 20px;
    margin-bottom: unset;
  }

  @include xms {
    font-size: 22px;
    margin-bottom: 0px;
  }
}

.label {
  position: absolute;
  right: 20px;
  top: 0px;
  transform: skew(-30deg) translateY(-70%);
  width: max-content;
  padding: 3px 15px;
  z-index: 20;
  background: #ff0000;
  border: 2px solid #e5b80b;
  filter: drop-shadow(0 0 7.5px #e5b80b);

  @include xs {
    padding: 1px 7px;
    right: 15px;
  }

  @include xxs {
    padding: 1px 4px;
    right: 5px;
  }
}

.labelText {
  font-size: 24px;
  transform: skew(30deg);
  line-height: 1.2;

  @include lg {
    font-size: 30px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 12px;
  }
}

.headerLogo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;

  height: 35px;
  width: 150px;

  @include lg {
    height: 60px;
    width: 200px;
    top: 40px;
  }
}

.buttonContainer {
  position: relative;
  display: flex;
  flex: 1;
  max-width: 360px;

  @include xl {
    max-width: 700px;
  }

  @include xs {
    flex: unset;
  }
}

.button {
  width: unset;
  flex: 1;
  display: flex;

  @include xs {
    width: 220px;
  }

  @include xxs {
    width: 150px;
  }
}
