@import "shared/styles";

.AlreadyFilledStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 0 auto;

  @include xs {
    width: 100%;
  }

  @include lg {
    width: 1200px;
  }
}

.prompt {
  font-size: 18px;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}

.childrenPrompt {
  text-align: start;
  font-size: 14px;

  @include lg {
    font-size: 28px;
  }
}

.radioButtonContainer {
  margin-top: 10px;
  margin-left: 2px;
  display: flex;
  background-color: $primary-light-blue;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;

  @include lg {
    padding: 10px;
    margin-top: 20px;
  }
}

.innerRadioButtonContainer {
  display: flex;
  gap: 10px;
  align-items: center;

  @include xs {
    gap: 5px;
  }

  @include lg {
    gap: 20px;
  }
}

.labelContainer {
  flex: 1;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;

  @include xs {
    min-height: 20px;
    padding-left: 5px;
  }

  @include lg {
    min-height: 60px;
    padding-left: 20px;
  }
}

.button {
  height: 38px;
  width: 150px;
  margin: 20px auto 0 auto;

  @include xs {
    width: 130px;
    height: 40px;
    margin: 15px auto 0 auto;
  }

  @include lg {
    margin: 40px auto 0 auto;
    height: 76px;
    width: 300px;
  }
}

.buttonText {
  font-size: 18px;
  letter-spacing: 1px;

  @include xs {
    font-size: 16px;
  }

  @include lg {
    font-size: 36px;
  }
}

.checkboxContainerButton {
  margin-top: 10px;
  display: flex;
  background-color: $primary-light-blue;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  margin-left: 2px;

  @include lg {
    padding: 10px;
    margin-top: 20px;
  }
}

.checkbox {
  width: 25px;
  height: 25px;
  margin-right: 5px;

  @include lg {
    width: 35px;
    height: 35px;
  }
}

.innerCheckboxContainer {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: underline;
  color: $primary-pink;
}

.error {
  font-size: 16px;
  margin-top: 10px;

  @include lg {
    font-size: 28px;
    margin-top: 20px;
  }
}

.spinner {
  margin: 25px auto 0 auto;

  @include lg {
    margin: 50px auto 0 auto;
  }
}
