@import "shared/styles";

.Select {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 36px;
}

.openButton {
  cursor: pointer;
  height: 100%;
}

.value {
  text-align: start;
  font-size: 16px;

  @include lg {
    font-size: 28px;
  }
}

// we cant simply hide the input because then autocomplete wont work
.fakeInput {
  visibility: collapse;
  font-size: 0px;
  position: absolute;
  border: none;
}

.valueContainer {
  background-color: $primary-white;
  border: none;
  color: $primary-black;
  height: 100%;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-family: $ff-primary;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.wrongValueContainer {
  border: 2px solid $primary-red;

  @include lg {
    border: 4px solid $primary-red;
  }
}

.arrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;

  @include lg {
    height: 20px;
  }
}

.optionsContainer {
  top: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
  overscroll-behavior: none;
  z-index: 10;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 3px;
}

.option {
  width: 100%;
  background-color: $primary-white;
}

.option:hover {
  background-color: $primary-light-blue;
}

.optionText {
  font-size: 16px;
  font-family: $ff-primary;

  @include lg {
    font-size: 28px;
  }
}

.selectedOption {
  background-color: $primary-pink !important;
}
