@import "shared/styles";

.AboutSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  box-sizing: border-box;
  scroll-margin: $header-height;
  width: 100%;
  max-width: 1200px;

  @include xl {
    scroll-margin: calc($header-height * 3);
  }

  @include lg {
    scroll-margin: calc($header-height * 1.5);
  }

  @include xxs {
    scroll-margin: calc($header-height * 0.5);
  }
}

.title {
  font-size: 40px;
  filter: drop-shadow(0 0 7.5px #5a1eac);
  margin-bottom: 20px;
  letter-spacing: 5px;

  @include xl {
    font-size: 120px;
  }

  @include xs {
    line-height: 1.1;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @include xxs {
    font-size: 30px;
  }
}

.subTitle {
  font-size: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  letter-spacing: 2px;

  @include xl {
    font-size: 60px;
  }

  @include xs {
    margin-bottom: 15px;
    font-size: 20px;
  }

  @include xxs {
    font-size: 15px;
  }
}

.content {
  font-size: 20px;
  margin-bottom: 20px;
  letter-spacing: 0px;

  @include xl {
    font-size: 40px;
  }

  @include xxs {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.imagesOuterContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
}

.imageContainer {
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: center;

  @include xs {
    flex-direction: column;
    margin-bottom: 15px;
  }

  @include xxs {
    gap: 0;
    margin-bottom: 5px;
  }
}

.imageWrapper {
  display: flex;
  flex: 1;
}

.image {
  max-width: 100%;
  height: auto;

  @include xs {
    height: auto;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  @include xms {
    height: 200px;
  }

  @include xxs {
    padding: 5px 10px;
    box-sizing: border-box;
  }
}

.warsawBaner {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
