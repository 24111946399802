@import "shared/styles";

.Header {
  display: flex;
  width: 100%;
  background-color: $opacity-purple;
  height: $header-height;
  position: fixed;
  z-index: 100;
  top: 0;
  align-items: center;
  justify-content: center;

  @include xl {
    height: calc($header-height * 3);
  }

  @include lg {
    height: calc($header-height * 1.5);
  }

  @include md {
    height: $header-height;
  }

  @include xxs {
    height: calc($header-height * 0.5);
  }
}

.sectionText {
  font-size: 16px;

  @include md {
    font-size: 14px;
  }

  @include xl {
    font-size: 45px;
  }

  @include lg {
    font-size: 27px;
  }
}

.selectedSectionText {
  color: $primary-pink !important;
}

.linksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @include md {
    gap: 8px;
  }

  @include xs {
    display: none;
  }
}

.logoContainer {
  display: flex;
  flex-direction: column;
  margin-right: 170px;

  @include md {
    margin-right: 20px;
  }

  @include xs {
    margin-right: 0;
    margin-left: 15px;
  }
}

.headerLogo {
  height: 35px;
  cursor: pointer;

  @include xl {
    height: 100px;
  }

  @include lg {
    height: 60px;
  }

  @include xxs {
    height: 20px;
  }
}

.cityLink {
  border: 1px solid $primary-white;
  border-radius: 10px;
  margin-top: 5px;
  padding: 3px 0;
  box-sizing: border-box;

  @include xs {
    display: none !important;
  }
}

.locationPin {
  //filter: invert(20%) sepia(89%) saturate(6343%) hue-rotate(303deg) brightness(103%) contrast(86%);
  filter: invert(100%);
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.link {
  display: flex;
  gap: 2px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
}

.link:hover .sectionText {
  color: $primary-pink;
}

.selectedLinkMark {
  height: 9px;
  width: 9px;

  @include xl {
    height: 20px;
    width: 20px;
  }
}

.hidden {
  visibility: hidden;
}

.languageChooser {
  position: relative !important;
  top: 0;
  left: 0;
}

.languageChooserContainer {
  @include xs {
    display: none;
  }
}

.iconsContainer {
  display: flex;
  gap: 15px;
  margin-left: 20px;
  margin-right: 15px;

  @include md {
    gap: 5px;
    margin-left: 10px;
  }

  @include xs {
    display: none;
  }
}

.mobileMenuButtonContainer {
  flex: 1;
  display: none;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;

  @include xs {
    display: flex;
  }
}

.mobileMenuButton {
  cursor: pointer;
  display: flex;
}

.mobileMenuButtonIcon {
  @include xxs {
    height: 15px;
  }
}

.headerLogoButton {
  display: flex;
  align-items: center;
}

.icon {
  height: 28px;
  width: 28px;
  cursor: pointer;

  @include xl {
    height: 50px;
    width: 50px;
  }
}

.resetLocationButtonContainer {
  width: 100%;

  @include xs {
    display: none;
  }
}
