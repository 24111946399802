@import "shared/styles";

.TextInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputContainerForm {
  display: flex;
  background-color: $primary-light-blue;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;

  @include xs {
    flex-direction: column;
  }

  @include lg {
    padding: 10px;
  }
}

.labelContainer {
  width: 220px;
  min-height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  align-items: center;

  @include xs {
    width: 100%;
    min-height: 20px;
  }

  @include xms {
    padding-left: 5px;
  }

  @include lg {
    width: 440px;
    min-height: 60px;
    padding-left: 20px;
  }
}

.label {
  color: $primary-black;
  font-family: $ff-secondary;
  font-size: 16px;

  @include lg {
    font-size: 28px;
  }
}

.inputForm {
  flex: 2;
  background: white;
  outline: none;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  font-family: $ff-primary;
  box-sizing: border-box;
  padding-left: 2px;
  min-height: 36px;

  @include xs {
    margin-left: 10px;
  }

  @include xms {
    margin-left: 5px;
  }

  @include lg {
    font-size: 28px;
  }
}

.inputClasses {
  width: 100%;
  border-radius: 28px;
  border: 0;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px;
  box-sizing: border-box;
  background-color: $primary-white;
  color: $primary-pink;
  font-weight: $fw-bold;
  font-family: $ff-primary;
  height: 60px;

  &::placeholder {
    color: $primary-pink;
  }

  @include lg {
    font-size: 24px;
  }

  @include xs {
    font-size: 16px;
    padding: 8px;
    height: 50px;
  }
}

.errorInput {
  border: 2px solid $primary-red;

  @include lg {
    border: 4px solid $primary-red;
  }
}

.errorText {
  margin: 0;
  color: $primary-red;
  font-family: $ff-primary;
  text-align: end;
  box-sizing: border-box;
  padding: 0 5px;
  font-size: 16px;

  @include lg {
    font-size: 28px;
    padding: 0 10px;
  }
}
