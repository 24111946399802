@mixin xxs {
  @media (max-width: 312px) {
    @content;
  }
}

@mixin xms {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 1060px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1415px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 2160px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 2800px) {
    @content;
  }
}
